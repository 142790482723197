.form {
    padding-top: 15px;
    padding-bottom: 15px;
}

.form + .form {
    margin-top: 15px;
}

.form-header {
    font-size: 1.2rem;
    margin-bottom: 30px;
}

.form-control[readonly] {
    background-color: $input-bg;

    &:focus {
        box-shadow: none;
        border-color: $input-border-color;
    }
}
