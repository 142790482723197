// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$primary: #e20613;

// Body
$body-bg: #f7f7f7;
$body-color: #0a154b;

// Typography
$font-family-sans-serif: 'Raleway', sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.6;


$input-btn-padding-y: .5rem;
$input-btn-padding-x: 1rem;

$form-group-margin-bottom: 1.2rem;

$custom-control-gutter: 1rem;
$custom-control-indicator-size: 1.5rem;

$calendar-header-color: #666;
$calendar-item-color: #666;

$calendar-item-hover-color: #fff;
$calendar-item-hover-bg: #666;

$calendar-item-active-color: #fff;
$calendar-item-active-bg: $primary;

$calendar-item-active-hover-color: #fff;
$calendar-item-active-hover-bg: $primary;

$calendar-item-disabled-color: #ddd;
