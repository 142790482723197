.app-datepicker {
    margin-bottom: 1rem;

    .ui-datepicker {
        min-width: 290px;
    }

    .ui-datepicker-header {
        display: flex;
        padding: 1rem 0;
    }

    .ui-datepicker-prev,
    .ui-datepicker-next,
    .ui-datepicker-title {
        flex: 1;
        display: flex;
        justify-content: center;
        padding: 0.5rem;
        font-size: 1.2rem;
        line-height: 1;
        text-align: center;
    }

    .ui-datepicker-prev,
    .ui-datepicker-next {
        span {
            cursor: pointer;
            color: $body-color;
            transition: all 0.5s ease-in-out;
            font-size: 1.4rem;

            &:hover {
                color: $primary;
            }
        }

        &.ui-state-disabled {
            visibility: hidden;
        }
    }

    .ui-datepicker-prev {
        order: -5;

        span { margin-right: auto; }
    }

    .ui-datepicker-next {
        order: 5;

        span { margin-left: auto; }
    }

    .ui-datepicker-title {
        line-height: 1.1;
    }

    .ui-datepicker-calendar {
        width: 100%;
        //background-color: $body-bg;

        th {
            text-align: center;
            color: $calendar-header-color;
            font-weight: 300;
            font-size: 0.7rem;
            padding: 0.375rem 0.1rem;
            //background-color: yellow;

            &::after {
                content: ".";
            }
        }

        td {
            padding: 0.1rem;
        }
    }

    .ui-state-default {
        display: block;
        padding: 0.5rem;
        font-size: 1.1rem;
        border: 1px solid #e3e3e3;
        background-color: #fff;
        color: $calendar-item-color;
        text-decoration: none;
        text-align: center;
        border-radius: 5px;
        transition: all 0.5s ease-in-out;

        &:hover {
            background-color: $calendar-item-hover-bg;
            color: $calendar-item-hover-color;
            border-color: $calendar-item-hover-bg;
        }
    }

    .ui-state-active {
        background-color: $calendar-item-active-bg;
        color: $calendar-item-active-color;
        border-color: $calendar-item-active-bg;

        &:hover {
            background-color: $calendar-item-active-hover-bg;
            color: $calendar-item-active-hover-color;
            border-color: $calendar-item-active-hover-bg;
        }
    }

    .ui-state-disabled .ui-state-default {
        color: $calendar-item-disabled-color;
        pointer-events: none;
        user-select: none;
        cursor: none;

        &:hover {
            color: $calendar-item-disabled-color;
            background-color: #fff;
            border-color: #e3e3e3;
        }
    }
}


/*
@mixin transition_all($sval: 0.50s,$art: all,$timing: ease-in-out) {
  -webkit-transition: $art $sval $timing;
  -moz-transition: $art $sval $timing;
  -o-transition: $art $sval $timing;
  transition: $art $sval $timing;
}

$objHover:#005A87;
$objHoverColor:#ffffff;

.app-datepicker {
    width: 100%;

    .ui-datepicker-inline {
        width: 100% !important;

        .ui-datepicker-header {
            position: relative;
            background-image: none;
            background-color: transparent;
            border: none;
            height: 60px;


            .ui-datepicker-title {
                font-weight: 200;
                color: #333;
                border: none;
                position: absolute;
                left: 70px;
                top: 8px;
                letter-spacing: 2px;
                font-size: 130%;
            }

            a {
                text-decoration: none !important;
                height: 35px;
                width: 35px;

                .ui-icon {
                    font-size: 140%;
                }

                background-image: none;
                background-color: transparent;
                position: absolute;

                &.ui-state-hover,
                &.ui-datepicker-prev-hover {
                    padding: 0;
                    margin: 0;
                    border: 0;
                }

                &.ui-datepicker-prev-hover {

                }

                &.ui-datepicker-prev {
                    span {
                        display: none !important;
                    }

                    cursor: pointer;
                    position: absolute;
                    left: 0px;
                    top: 8px;
                    font-size: 140%;
                    font-family: FontAwesome;

                    &:before {
                        content: "\f053";
                    }
                }

                &.ui-datepicker-next {
                    cursor: pointer;

                    span {
                        display: none !important;
                    }

                    font-family: FontAwesome;
                    position: absolute;
                    left: 30px;
                    font-size: 140%;
                    top: 8px;

                    &:before {
                        content: "\f054";
                    }
                }
            }
        }


        .ui-datepicker-other-month {
            text-align: left;

            span {
                padding-left: 10px;
                text-align: left;
                display: inline-block;
                width: 25px;
                font-size: 80% !important;
                @media (min-width: 650px) {
                    width: 40px;
                    opacity: 0.4;
                    font-size: 100%;
                }
            }
        }


        table {
            min-height: 450px;

            td {
                padding: 5px;
                ertical-align: middle;
                text-align: center;
            }

            td,
            th {
                min-height:45px;
            }

            thead {
                th {
                    text-align: left;
                    vertical-align:top;

                    span {
                        color: black;
                        display: block;
                        text-align: center;
                        font-weight: 200;
                        width: 25px;
                        font-size: 80%;

                        @media (min-width: 650px) {
                            width: 40px;
                            font-size: 100%;
                        }
                    }
                }
            }

            tbody td {

                a,
                span,
                .ui-state-default {
                    text-decoration: none !important;

                    @include transition_all(0.5s);

                    background-color: rgba(#efefef, 0.4);
                    border-radius: 50%;
                    display: flex;
                    background-image: none;
                    border: none;
                    width: 25px;
                    height: 25px;
                    font-size: 80%;

                    @media (min-width: 650px) {
                        width: 40px;
                        height: 40px;
                        font-size: 100%;
                    }

                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;
                    flex-direction: column;
                }

                &.app-free-date {
                    a {
                        &:hover,
                        &.ui-state-active {
                            background-color: $objHover;
                            color: $objHoverColor;
                        }
                    }
                }

                &.ui-state-disabled,
                .ui-datepicker-unselectable  {
                    span {
                        color:#dddddd;
                    }
                }

                &.app-block-date {
                    a {
                        color: darkred;
                    }
                }


                &.ui-datepicker-other-month{
                    .ui-state-default{
                        height:25px;
                        width:25px;
                        display:flex;
                        flex-wrap:wrap;
                        justify-content:center;
                        align-items:center;

                        @media (min-width: 650px) {
                            width: 40px;
                            height: 40px;
                            font-size: 100%;
                        }
                    }
                }

            }
        }
    }
}
*/
