body.app {
    display: flex;
    overflow-x: hidden;
    min-height: 100vh;
    flex-direction: column;
}

.app-header {
    background-color: #fff;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.app-logo {
    width: 180px;
    height: 56px;
    background: transparent url(../../images/kwa-logo.svg) center center no-repeat;
    background-size: contain;
    margin: 0;

    span { display: none; }
}

.app-title {
    background-color: #fff;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #e3e3e3;
}

.app-subline {
    font-size: 1.5rem;
    font-weight: 300;
    margin-bottom: 0;
}

.app-main {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.app-footer {
    margin-top: auto;
    color: #888;
}

.app-footer-nav {
    background-color: #ededed;
}

.app-alert {
    .alert {
        margin-top: 1rem;
        margin-bottom: 2rem;
    }
}

.app-welcome {
    .app-logo {
        width: 290px;
        height: 90px;
    }
}

.app-timepicker-wrap {
    background-color: #fff;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.app-step {
    background-color: $calendar-header-color;
    color: #fff;
}

.app-step-inner {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-align: center;
}

.app-buttons {
    margin-top: 2rem;
    text-align: center;

    .btn {
        margin-bottom: 1rem;

        @include media-breakpoint-up(sm) {
            display: block;
            width: 100%;
        }
    }
}

.app-image {
    @include media-breakpoint-down(xs) {
        margin-left: -1rem;
        margin-right: -1rem;
        max-width: 100vw;
    }
}

@include media-breakpoint-up(md) {
    .app-header {
        border-bottom: 1px solid #e3e3e3;
    }

    .app-title {
        background-color: transparent;
        padding: 0;
        border-bottom: 0;
    }

    .app-main {
        padding-top: 0;
    }

    .app-title-inner,
    .app-main-inner,
    .app-timepicker-wrap-inner {
        background-color: #fff;
        max-width: 460px;
        padding: 1rem 2rem;
        margin: 2rem auto 0;
        border: 1px solid #e3e3e3;
    }

    .app-calendar-form-inner,
    .app-buttons {
        max-width: 460px;
        margin: 2rem auto 0;
    }

    .app-title-inner {
        padding-bottom: 0;
        border-bottom: 0;
    }

    .app-subline {
        margin-top: 1rem;
    }

    .app-welcome {
        .app-main-inner {
            border-top: 0;
            margin-top: 0;
        }
    }

    .app-alert .alert {
        max-width: 460px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 2rem;
        margin-bottom: 0;
    }

    .app-timepicker-wrap {
        background-color: transparent;
    }

    .form-checkout {
        .app-main-inner {
            padding-top: 2rem;
        }
    }
}
