.app-timepicker {
    color: $calendar-header-color;
    min-width: 290px;
}

.app-timepicker-row {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.app-timepicker-grid {
    display: flex;
    flex-wrap: wrap;
}

.app-timepicker-item {
    flex: 0 0 25%;
    max-width: 25%;
    width: 100%;
    padding: .25rem;
}

.app-timepicker-date {
    min-width: 60px;
}

.app-timepicker-date-day {
    display: block;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1;
}

.app-timepicker-date-weekday {
    display: block;
    font-size: 0.8rem;
    line-height: 1.8rem;

    &::after {
        content: ".";
    }
}

.app-timepicker-btn {
    display: block;
    border: 1px solid #e3e3e3;
    background-color: #fff;
    color: $calendar-item-color;
    transition: all 0.5s ease-in-out;
    padding-left: 0.25rem;
    padding-right: 0.25rem;

    &.disabled {
        color: $calendar-item-disabled-color;
        background-color: #fff;
        border-color: #e3e3e3;
    }

    &:focus {
        box-shadow: none;
    }

    &:hover {
        background-color: $calendar-item-hover-bg;
        color: $calendar-item-hover-color;
        border-color: $calendar-item-hover-bg;
    }

    &.active {
        background-color: $calendar-item-active-bg;
        color: $calendar-item-active-color;
        border-color: $calendar-item-active-bg;

        &:hover {
            background-color: $calendar-item-active-hover-bg;
            color: $calendar-item-active-hover-color;
            border-color: $calendar-item-active-hover-bg;
        }
    }
}
